import React, { useState } from 'react'

import iconFolder from 'images/icons/folder.svg'
import iconUser from 'images/icons/user.svg'

export default function DeliveryDropdown({ id, option }) {
  const [dropdownVisible, setDropdownVisible] = useState(false)
console.log('id', id)
  return (
    <>
      <div className="relative">
        <button onClick={() => setDropdownVisible(!dropdownVisible)} type="button">
          Delivery options
          <i className="fa fa-caret-down ml-2" />
        </button>

        {dropdownVisible && (
          <div className="dropdown" style={{ display: dropdownVisible && 'block' }}>
            <button className="dropdown__link w-full" type="button">
              Delivery
              <img alt="" className="inline" src={iconFolder} />
            </button>

            <button className="dropdown__link w-full" type="button">
              In Person
              <img alt="" className="inline" src={iconUser} />
            </button>
          </div>
        )}
      </div>
    </>
  )
}

import React from 'react'
import Slider from 'react-slick'

export default function ArtworksImageCarousel({ images }) {
  const settings = {
    autoplay: false,
    arrows: true,
    dots: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <>
      <Slider {...settings}>
      { images.map((image) => (
        <div className="bg-spring-wood text-center p-8">
          <img alt="" src={image} />
        </div>
      ))}
      </Slider>
    </>
  )
}

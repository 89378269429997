import React from 'react'
import Slider from 'react-slick'

export default function SimilarArtworks(artworks, headline) {
  const settings = {
    autoplay: false,
    arrows: true,
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  }

  return (
    <>
      <h3 className="font-normal mb-8 text-clay-creek text-2xl">{artworks.headline}</h3>

      <div className="border-b border-alto mb-16 pb-16">
        <Slider {...settings}>
          { artworks.artworks.map((artwork) => (
          <div className="px-4" key={artwork.name}>
            <div className="flex flex-col relative w-full">
              <a className="absolute bottom-0 left-0 right-0 top-0 z-10" href={artwork.artwork_path }></a>

              <div style={{width:"280px", height:"280px", maxHeight: "300px", maxWidth: "300px"}} className="bg-spring-wood flex flex-auto items-center justify-center mb-4 p-2">
                <div>
                  <img alt="" src={artwork.image} style={{ height:"280px", width:"280px", objectFit: "contain"}}/>
                </div>
              </div>

              <p>{artwork.name}</p>

              <p className="text-clay-creek">{artwork.artist}</p>
            </div>
          </div>
          ))}
        </Slider>
      </div>
    </>
  )
}
